<template>
  <v-app>
    <helps />
    <salvation />
    <app-footer />
    <features-view />
  </v-app>
</template>

<script>
// Extensions
import View from "@/views/View";

export default {
  name: "App",

  metaInfo: { title: "App" },

  components: {
    AppFooter: () => import("@/views/sections/AppFooter"),
    FeaturesView: () => import("@/views/sections/Features"),
    Salvation: () => import("@/views/sections/Salvation"),
    Helps: () => import("@/components/Helps"),
    LocaleSelect: () => import("@/views/sections/LocaleSelect")
  },

  extends: View,

  props: {
    id: {
      type: String,
      default: "app"
    }
  }
};
</script>
